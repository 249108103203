



























































import { Company, User } from '@app/models';
import { debounce } from '@bcase/core';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import { company } from '../store/modules/company-module';
import { user } from '../store/modules/user-module';
import { NavGuard } from '../utils/decorators';

const PATHS_INVITE = ['account'];

@Component
export default class OnBoarding extends Vue {
  public company: Company = {} as Company;
  public user: User.Settings = {} as User.Settings;

  private awaitVerify = false;
  private debounceSaveCompany = debounce(this.saveCompany.bind(this), 2000);
  private debounceSaveUser = debounce(this.saveUser.bind(this), 2000);
  private initialized = false;

  public get nextStep() {
    const { steps } = this;
    const current = steps.findIndex(s => s.current);
    return steps[current + 1];
  }

  public get prevStep() {
    const { steps } = this;
    const current = steps.findIndex(s => s.current);
    return steps[current - 1];
  }

  public get steps() {
    const paths = isInitialUser() ? webpack.FEATURES.on_boarding : PATHS_INVITE;
    const routes = paths.map(r => `on-boarding-${r}`);
    const current = routes.indexOf(this.$route.name || '');

    return routes.map((route, i) => ({
      name: route,
      label: `on-boarding:step-${route}`,
      current: i === current,
      active: i <= current,
    }));
  }

  public get verified() {
    return this.$user.data && this.$user.data.status.verified;
  }

  created() {
    if (!this.$user.data || !this.$company.data) return;

    this.company = this.$company.data;
    this.user = {
      gender: this.$user.data.gender,
      mfa: this.$user.data.mfa,
      name: { ...this.$user.data.name },
      phone: this.$user.data.phone,
    };

    this.$nextTick().then(() => (this.initialized = true));
    this.$app.loader();
  }

  @NavGuard
  public beforeRouteEnter(to: Route, from: Route, next: Function) {
    const paths = isInitialUser() ? webpack.FEATURES.on_boarding : PATHS_INVITE;
    if (to.name === 'on-boarding')
      return next({ name: 'on-boarding-' + paths[0], params: to.params });

    next();
  }

  @Watch('company', { deep: true })
  public watchCompany(company: Company) {
    if (this.initialized) this.debounceSaveCompany(company);
  }

  @Watch('user', { deep: true })
  public watchUser(user: User.Settings) {
    if (this.initialized) this.debounceSaveUser(user);
  }

  @Watch('verified')
  public async watchVerify(verified: boolean) {
    if (!verified || !this.awaitVerify) return;

    const dialog = document.querySelector('bce-dialog');
    if (!dialog) return;

    await dialog.close();
    await this.$bce.alert(this.$t('done'), this.$t('verified-true-message'), {
      ok: this.$t('next'),
    });

    this.complete();
  }

  public async cancel() {
    const batch = this.$firebase
      .batch()
      .delete(this.$firebase.doc('user/' + this.$user.id));

    if (isInitialUser()) {
      const company = this.$user.data!.company[0];
      batch.delete(this.$firebase.doc('company/' + company));
    }

    await batch.commit();
    await this.$user.signOut();
    this.$router.push('/');
  }

  public prev() {
    // Navigate to prev step
    this.$router.push({ name: this.prevStep.name });
  }

  public async next() {
    // Validate form
    const errors = await this.validateForm();
    if (errors.length) return;

    // Navigate to next step
    if (this.nextStep) this.$router.push({ name: this.nextStep.name });
    else this.complete();
  }

  public async complete() {
    if (!this.verified) {
      this.awaitVerify = true;
      const title = this.$t('warning');
      const message = this.$t('verified-false-message');
      await this.$bce.alert(title, message, { ok: false });
      this.awaitVerify = false;
      return;
    }

    try {
      this.$app.loader(this.$t('complete-loader'));
      await this.$user.finish();
      this.$app.loader();

      await this.$bce.alert(
        this.$t('complete-title'),
        this.$t('complete-message'),
        { ok: this.$t('complete-ok') }
      );

      const redirect = `/c/${this.$company.id}/research`;
      await this.$user.signOut();
      window.location.href = this.$auth.createURL(redirect);
    } catch (e) {
      this.$bce.message(e.message, 10, 'error');
      this.$app.loader();
    }
  }

  private async saveCompany(company: Company) {
    const c = { ...company };
    if (!c.created) c.created = fb.firestore.Timestamp.now();

    await this.$firebase.doc('/company/' + c.id).set(c, { merge: true });
    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }

  private async saveUser(user: User.Settings) {
    const errors = await this.validateForm(true);
    if (errors.length) return;

    const u = { ...user };
    if (!u.gender) delete u.gender;

    await this.$firebase.doc('/user/' + this.$user.id).set(u, { merge: true });
    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }

  private async validateForm(silent = false) {
    const form = this.$el.querySelector('bce-form');
    return form ? await form.validate(silent) : [];
  }
}

const isInitialUser = () => {
  const users = company.data ? company.data.users : [];
  return users.length === 1 && users[0] === user.id;
};
